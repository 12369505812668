import { Button } from "antd"
import React, { useState } from "react"
import { unlockStore } from "../../../services/superAdManageCompany"
import { openNotificationFail } from "../../../components/notification"

const UnlockedButton = ({ record, handleGetListCompany }) => {
  const [isLoading, setIsLoading] = useState(false)
  const handleUnlocked = async () => {
    setIsLoading(true)
    const { response } = await unlockStore({ storeId: [record.id] })
    if (response.status == 200) {
      setIsLoading(false)
      handleGetListCompany()
    } else {
      setIsLoading(false)
      openNotificationFail("営業開できません。", "", "#f81d22")
    }
  }
  return (
    <Button
      type="ghost"
      loading={isLoading}
      onClick={(e) => {
        e.preventDefault()
        handleUnlocked()
      }}
    >
      {"営業再開"}
    </Button>
  )
}

export default UnlockedButton
