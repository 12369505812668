const textAndRules = {
  pleaseInput: "※必須項目が未入力です。",
  pleaseSelect: "※必須項目が未選択です。",
  emailValidateNotEmail: "有効なメールアドレスではありません。",
  emailRequire: "メールアドレスを入力してください。",
  notiNetworkError1: "サーバーへ接続できません。",
  notiNetworkError2: "再度確認してください。",
  pleaseInputAfterName: "を入力してください。",
  passwordMin8Ch: "パスワードは8文字以上である必要があります。",
  addAdressSuccess: "成功したアドレスを追加。",
  addAdressFail: "アドレスの追加に失敗しました。",
  tryAgain: "後でもう一度やり直してください。",
  pleaseInputANumberPhoneAvaiable: "有効な電話番号を入力してください。",
  twoPasswordDontMatch: "入力した2つのパスワードが一致しません。",
  saved: "保存しました。",
  oldPasswordError: "古いパスワードが正しくありません。",
  addedCart: "買い物かごに入れました。",
  liked: "お気に入りに追加しました。",
  unliked: "お気に入りのリストを削除しました。",
  updateFail: "更新に失敗しました。",
  updateSuccess: "正常に更新されました。",
  deleteCartSuccess: "カートが正常に削除されました。",
  deleteCartFail: "カートの削除に失敗しました。",
  pleaseChooseAdress: "発送先をご入力ください。",
  pleaseChoosePaymentMethod: "決済方法をご入力ください。",
  notSupportPaymentMethodYouChoose:
    "選択した支払い方法はまだサポートされていません。",
  chooseNotSuccess: "選択流れに失敗。もう一度お試しください。",
  errorDuringPayment: "支払い処理がエラー発生された。もう一度お試しください。",
  paymentNotSuccess: "支払いに失敗しました。",
  uploadCSVSuccess: "正常にアップロード。",
  uploadCSVFail: "ファイルのアップロードに失敗しました。",
  dowloadCSVFail: "ファイルのダウンロードに失敗しました。",
  uploadZipSuccess: "ジップファイルをアップロードしました。",
  errorGetList: "リストの取得エラー",
  updateStatusSucess: "更新ステータス成功。",
  updateStatusFail: "更新ステータスエラー。",
  askBeforeDeleteShip: "これらの配送伝票を削除してもよろしいですか?",
  askBeforeDeleteProduct: "この商品を削 除してもよろしいでしょうか？",
  npCheckOrderRejected:
    "このオーダーIDにはNP掛け払いが審査結果がNGになりました。キャンセルしますか？",
  messageSent: "通信が送信しました。",
  reportOutOfStock: "完売通知を送信してもよろしいでしょうか？",
  accountLock: "停止する",
  lockedSuccessfully: "停止されました。",
  lockedFailed: "停止できません。",
}
export default textAndRules
