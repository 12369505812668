import React, { useEffect, useState } from "react"
import { Spin, Table, Space, Select, Button } from "antd"
import {
  deleteListCompany,
  getListCompany,
} from "../../../services/superAdManageCompany"

import "../style.scss"
import { paginationCustom } from "../../../components/paginationCustom"
import { defineTableListCompany } from "./defineTableListCompany"
import SearchTableListCompany from "./SearchTableListCompany"
import { navigate } from "gatsby"
import queryString from "query-string"

import {
  openNotificationDeleteSuccess,
  openNotificationFail,
  openNotificationNoIcon,
} from "../../../components/notification"
import textAndRules from "../../../utils/textAndRules"

function ListCompany({ location }) {
  const [isLoading, setIsLoading] = useState(false)
  const [listCompany, setListCompany] = useState(null)
  const [total, setTotal] = useState(0)

  const [bulkOperation, setBulkOperation] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [tableParams, setTableParams] = useState(null)
  const handleGetListCompany = async () => {
    setIsLoading(true)
    const query = queryString.parse(location.search)
    const dataSent = {
      offset:
        (tableParams.pagination.current - 1) * tableParams.pagination?.pageSize,
      limit: tableParams.pagination.pageSize,
      q: query?.keyword ? query?.keyword : null,

      user_type:
        query?.user_type?.length > 0 ? JSON.stringify(query?.user_type) : null,
    }
    if (query?.order && query?.sorterField) {
      switch (query?.order) {
        case "ascend":
          dataSent.orderASC = [query?.sorterField]
          break
        case "descend":
          dataSent.orderDESC = [query?.sorterField]
          break
      }
    }
    const { response } = await getListCompany(dataSent)
    if (response.status == 200) {
      const query = queryString.parse(location.search)
      if (
        response.data.count &&
        Math.ceil(response.data.count / 10) < Number(query?.pageCurrent)
      ) {
        const newQueryStr = queryString.stringify({
          ...query,
          pageCurrent: 1,
        })
        navigate(
          `/super-list-company/?${queryString.stringify(newQueryStr)}`,
          undefined,
          {
            shallow: false,
          }
        )
        return
      }
      setListCompany(response.data.stores)
      setTotal(response.data.count)

      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (tableParams) {
      handleGetListCompany()
    }
  }, [tableParams])

  const handleTableChange = (pagination, filters, sorter) => {
    const query = queryString.parse(location.search)
    const newQueryStr = {
      ...query,
      pageCurrent: pagination.current,
    }
    delete newQueryStr.sorterField
    delete newQueryStr.order

    if (sorter.order) {
      newQueryStr.sorterField = sorter.field
      newQueryStr.order = sorter.order
    }
    navigate(
      `/super-list-company/?${queryString.stringify(newQueryStr)}`,
      undefined,
      {
        shallow: false,
      }
    )
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const columns = defineTableListCompany({ location, handleGetListCompany })

  const deleteOperation = async () => {
    setSubmitting(true)
    if (!bulkOperation || selectedRowKeys.length === 0) {
      setSubmitting(false)
      return
    }
    if (bulkOperation == "deleteAll") {
      const { response } = await deleteListCompany({ storeId: selectedRowKeys })
      if (response.status == 200 || response.status == 201) {
        openNotificationDeleteSuccess("削除に成功しました。", "", "#389e0d")
        setSelectedRowKeys([])
        handleGetListCompany()
        setSubmitting(false)
      } else {
        setSubmitting(false)
        openNotificationFail("削除に失敗しました。", "", "#f81d22")
      }
    }
    if (bulkOperation == "lockStore") {
      const { response } = await deleteListCompany({
        storeId: selectedRowKeys,
        type: "deactivate",
      })
      if (response.status == 200 || response.status == 201) {
        openNotificationNoIcon(textAndRules.lockedSuccessfully, "", "#389e0d")
        setSelectedRowKeys([])
        setBulkOperation(null)
        handleGetListCompany()
        setSubmitting(false)
      } else {
        setSubmitting(false)
        openNotificationFail(textAndRules.lockedFailed, "", "#f81d22")
      }
    }
  }

  useEffect(() => {
    const query = queryString.parse(location.search)
    if (
      Number(query?.pageSize) &&
      Number(query?.pageCurrent) &&
      query?.pageSize == "10"
    ) {
      setTableParams({
        pagination: {
          current: Number(query?.pageCurrent),
          pageSize: 10,
        },
      })
    } else {
      const newQueryStr = queryString.stringify({
        pageSize: 10,
        pageCurrent: 1,
      })
      navigate(`/super-list-company/?${newQueryStr}`)
    }
  }, [location])
  return (
    <div
      style={{
        width: "100%",
        // padding: "16px 20px",
        background: "#FFFFFF",
        borderRadius: "6px",
      }}
    >
      <Spin spinning={isLoading}>
        <div className="pb-5">
          <SearchTableListCompany loading={isLoading} location={location} />
        </div>
        <div className="border border-[rgba(0,0,0,0.06)]">
          <div className="p-[10px] bg-[#F3F3F3]">
            <Space direction="vertical">
              <Space style={{ paddingBottom: 10 }}>
                <div className="text-[#333]">{"一括操作"}</div>
                <Select
                  allowClear
                  placeholder={"操作を選択"}
                  style={{ width: 180 }}
                  value={bulkOperation}
                  onChange={(e) => {
                    setBulkOperation(e)
                  }}
                >
                  <Select.Option value={"deleteAll"}>
                    {"一括削除"}
                  </Select.Option>
                  <Select.Option value={"lockStore"}>
                    {textAndRules.accountLock}
                  </Select.Option>
                </Select>
                <Button
                  loading={submitting}
                  disabled={selectedRowKeys?.length == 0}
                  onClick={deleteOperation}
                >
                  {"実行"}
                </Button>
              </Space>
            </Space>
          </div>
          {tableParams && (
            <Table
              style={{ cursor: "pointer" }}
              columns={columns}
              dataSource={listCompany}
              loading={isLoading}
              rowSelection={rowSelection}
              scroll={{ x: 1500 }}
              rowKey="id"
              onChange={handleTableChange}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    if (event.target?.textContent == "営業再開") {
                      return
                    }
                    navigate(`detail?storeId=${record.id}`)
                  },
                }
              }}
              pagination={{
                ...tableParams.pagination,
                total: total,
                itemRender: paginationCustom,
                showSizeChanger: false,
                showTotal: (total, range) => (
                  <div>
                    {range[0]}~{range[1]} 件 表示 &nbsp;&nbsp;&nbsp; (全
                    {total}件)
                  </div>
                ),
              }}
            />
          )}
        </div>
      </Spin>
    </div>
  )
}

export default ListCompany
